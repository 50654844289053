import {Component} from '@angular/core'
import version from '../assets/package.json'
import {ConfigService} from './services/config.service'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public version = version.version

  constructor(public configService: ConfigService) {
  }

}
