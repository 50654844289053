<div class="spb-holder">
  <spb-header></spb-header>
  <router-outlet></router-outlet>
  <div class="filler"></div>
  <spb-footer
    [license]="true"
    [token]="(configService.configState$ | async)?.token"
    [version]="version"
    copyrightYears="2021-2024"></spb-footer>
</div>

